import { StatusBar } from 'expo-status-bar';
import { useEffect } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import RoutingApi from './src/api/routing';

export default function App() {

  useEffect(() => {
    forwardLink(window.location.href)
  },[window.location.href])

  const forwardLink = async(url:string) => {
    let destination_url = await RoutingApi.converUrl(url)
    window.location.replace(destination_url)
  }

  return (
    <View style={styles.container}>
      <ActivityIndicator size='large' color={'blue'}/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
