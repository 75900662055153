import axios from 'axios';
import { getEndpoints } from '../endpoints';
const { ANALYTICS_SVC_API } = getEndpoints();
const RoutingApi = {
    converUrl: async(url:string):Promise<string> => {
        try {
            const resp = await axios.post(`${ANALYTICS_SVC_API}/v1/mappings/url/convert`, { url })
            console.log(resp.data);
            if(!resp.data.destination_url){ throw new Error('Failed to convert - falling back to bettoredge.com') }
            return resp.data.destination_url
        } catch (e) {
            console.log(e)
            return 'https://www.bettoredge.com'
        }
    }
}

export default RoutingApi