const endpoints = {
    development: {
        AUTH_SVC_API: 'http://192.168.0.52:5000',
        SOC_SVC_API: 'http://192.168.0.52:3011',
        ANALYTICS_SVC_API: 'http://192.168.0.52:3013',
        WS_SVC_API: 'ws://192.168.0.52:3000'
    },
    production: {
        AUTH_SVC_API: 'https://api.players.bettoredge.com',
        SOC_SVC_API: 'https://api.social.bettoredge.com',
        ANALYTICS_SVC_API: 'https://be-analytics-svc.herokuapp.com',
        WS_SVC_API: 'https://be-ws.herokuapp.com'
    }
}


export const getEndpoints = () => {
    if(__DEV__){ return endpoints.development }
    return endpoints.production
}

